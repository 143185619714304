<template>
    <v-container class="page-container" fill-height fluid grid-list-xl>
        <v-layout wrap id="configuration" v-if="isEverythingReady">
            <template v-if="payoutSessions.length > 0">
               <!-- Header -->
               <div class="w-100 mb-3 m-t--40">
                   <p class="grey-700 fs-16 lh-24">Settling Payments > Draft Payout & Invoices >  <span class="bold-font">{{ id }}</span> </p>
               </div>
               <div class="w-100 flex-display flex-row justify-space-between align-center">
                   <span class="page-title text-xs-left mb-0">{{ type }} {{ id }}</span>
               </div>
               <div class="border-bottom-2px w-100 my-3"></div>
               <div class="w-100 flex-display flex-row justify-space-between ">
                   <div class="flex-display flex-column justify-start mt-3">
                       <p class="grey-700 fs-16 lh-28">To</p>
                       <p class="mb-0 grey-900 fs-20 lh-28">{{ dueFromName }}</p>
                       <p class="grey-900 fs-20 lh-28">{{ dueFromEmail }}</p>
                   </div>

               </div>
               <template>
                   <table id="invoiceDetails" class="table border-1px mt-2">
                       <thead>
                       <tr>
                           <th class="text-left grey-700">Client</th>
                           <th class="text-left grey-700">Session Date</th>
                           <th class="text-left grey-700">Organization</th>
                           <th class="text-left grey-700">Claim Invoice Id</th>
                           <th class="text-left grey-700">Session Fee</th>
                           <th class="text-left grey-700">Therapist Fee</th>
                           <th class="text-left grey-700">Therapist Received</th>
                           <th class="text-left">Net due to therapist</th>
                           <th class="text-left">Net due to MOT</th>
                       </tr>
                       </thead>
                       <tbody>
                       <template v-for="payoutSession in payoutSessions">
                           <tr>
                               <td>{{ payoutSession.client_name }}</td>
                               <td>{{ payoutSession.session_date }}</td>
                               <td>{{ payoutSession.org }}</td>
                               <td>{{ payoutSession.claim_invoice_id }}</td>
                               <td>{{ payoutSession.session_fee }}</td>
                               <td>{{ payoutSession.therapist_fee }}</td>
                               <td>{{ payoutSession.therapist_received }}</td>
                               <td>{{ payoutSession.due_to_psychologist }}</td>
                               <td>{{ payoutSession.due_to_mot }}</td>
                           </tr>
                       </template>
                       </tbody>
                       <tr class="height-15"></tr>
                       <tfoot>
                       <tr>
                           <td colspan="5"></td>
                           <td colspan="2" class="border-1px grey-900 bold-font text-right">Running total</td>
                           <td class="border-1px ">£{{ netDueToTherapist }}</td>
                           <td>£{{ netDueToMot }}</td>
                       </tr>
                       </tfoot>
                   </table>
               </template>
           </template>
           <template v-else>
               <div class="w-100 h-100 flex-display flex-row justify-center align-center">
                   <p>No record found!</p>
               </div>
           </template>
        </v-layout>
    </v-container>
</template>

<script>
import DatePicker from "../../components/common/DatePicker";

export default {
    name: "DraftDetail",
    components: {
        DatePicker
    },
    data() {
        return {
            isEverythingReady: false,
            invoice_date: null,
            sent_date: null,
            due_date: null,
            date_paid: null,
            draftId: null,
            payoutSessions: null,
            id: null,
            type: '',
            status: '',
            netDue: 0,
            netDueToTherapist: 0,
            netDueToMot: 0,
            dueToEmail: '',
            dueToName: '',
            dueFromName: '',
            dueFromEmail: '',
        }
    },
    mounted() {
        this.draftId = this.$route.params.id;
        this.getInvoiceDetails();
    },
    methods: {
        getInvoiceDetails() {
            this.isEverythingReady = false;
            let loader = this.$loading.show()
            this.$http.get(this.url + 'draft/detail' , {
                params: {
                    id: this.draftId,
                    timezone: this.timezone
                }
            }).then(response => {
                if (response.data) {
                    this.id = response.data.draft_id;
                    this.type = response.data.type;
                    this.status = response.data.status;
                    this.netDue = response.data.amount_due;
                    this.netDueToMot = response.data.net_due_to_mot;
                    this.netDueToTherapist = response.data.net_due_to_therapist;
                    this.due_date = response.data.due_date;
                    this.invoice_date = response.data.invoice_date;
                    this.date_paid = response.data.paid_date;
                    this.sent_date = response.data.sent_date;
                    if (response.data.due_to) {
                        this.dueToEmail = response.data.due_to.email;
                        this.dueToName = response.data.due_to.name;
                    }
                    if (response.data.due_from) {
                        this.dueFromEmail = response.data.due_from.email;
                        this.dueFromName = response.data.due_from.name;
                    }
                }
                this.payoutSessions = response.data.data ? response.data.data : [];
                this.isEverythingReady = true;
                loader.hide();
            }).catch(error => {
                loader.hide()
                this.$toastr('error', error, 'Error')
            })
        },
    },
}
</script>

<style scoped>

.input-select {
    border: 2px solid #308BC3 !important;
}
#billDetails th:nth-child(-n+7){
    font-weight: normal;
    color: #757575;
    font-size:16px;
}

th:last-child,
td:last-child,
th:nth-last-child(2),
td:nth-last-child(2) {
    text-align: right;
    background-color: #F1F1F1;
    font-weight: bold;
}
tbody tr:last-child{
  border: 1px solid #D4D4D4 !important;
}
</style>
